<template>
  <div class="scoreMethod">
    <el-dialog
      title="给分方式"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="title">
        <!-- {{ indexQuestion }} -->
        <span class="question-name">主观题{{ indexQuestion.questionNum }}</span>
        <span>满分：{{ indexQuestion.questionScore }}</span>
      </div>
      <div style="margin-bottom: 18px">
        给分方式：
        <el-radio-group v-model="scoreTypeValue">
          <el-radio
            v-for="(item, index) in scoreTypeList"
            :key="index"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <div v-if="scoreTypeValue != 0">
        <div
          v-for="(item, index) in exammarkPointList"
          :key="index"
          class="question-item"
        >
          <div>
            <span class="tips"
              >分项{{ item.questionNum }}-{{ item.name }}
            </span>
            <span class="score-box"
              >分值：<el-input
                v-model="item.score"
                placeholder="请输入分值"
              ></el-input
            ></span>
          </div>
          <div class="check-type">
            给分方式：
            <el-radio-group v-model="item.step" @change="checkItem(item)">
              <el-radio :disabled="indexQuestion.hasMark > 0" label="2"
                >自定义</el-radio
              >
              <el-radio :disabled="indexQuestion.hasMark > 0" label="1"
                >设置步长</el-radio
              >
            </el-radio-group>
          </div>
          <el-input
            v-if="item.step == 1"
            v-model="item.stepValue"
            placeholder="请输入内容"
            style="width: 223px"
          ></el-input>
          <el-input
            v-if="item.step == 2"
            v-model="item.markScore"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>

      <div class="warning-tips">
        <span>注意：</span>
        <template v-if="scoreTypeValue == 0">
          1、保存后，系统将清除全部分项，请谨慎操作；<br />
          2、若要设置分项给分，请点击 设置分项给分 重新设置；
        </template>
        <template v-else>
          1、若要新增分项、设置打分区域、设置仲裁，请点击继续编辑跳转到设置分项页继续设置；<br />
          2、若已有教师开始阅卷，则不可切换给分方式<br />
          3、若要切换为区域给分，请点击 继续编辑 进行打分区域设置
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <template v-if="scoreTypeValue == 0">
          <el-button type="primary" @click="setRule()"> 确 定 </el-button>
        </template>
        <template v-else>
          <el-button type="primary" @click="goEdit()"> 继续编辑 </el-button>
          <el-button
            type="primary"
            :loading="btnLoading"
            :disabled="indexQuestion.pointType == 2 && scoreTypeValue == 1"
            @click="submit()"
          >
            保 存
          </el-button>
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const scoreTypeList = [
  { label: "一键给分", value: 0 },
  { label: "分项给分-自由打分", value: 2 },
  { label: "分项给分-区域打分", value: 1 },
];
import { scoreTypeItem } from "@/core/util/constdata";
import {
  exammarkPoint,
  saveExammarkPoint,
  exammarkRuleScore,
} from "@/core/api/exam/examCorrectTask";
import { setStore } from "@/core/util/store";
export default {
  name: "ScoreMethod",
  components: {},
  data() {
    return {
      scoreTypeList: scoreTypeList,
      scoreTypeItem: scoreTypeItem,
      scoreTypeValue: 0,
      dialogVisible: false,
      btnLoading: false,
      indexQuestion: {},
      exammarkPointList: [],
    };
  },
  created() {},
  methods: {
    goEdit() {
      let indexQuestion = JSON.parse(JSON.stringify(this.indexQuestion));
      indexQuestion.id = indexQuestion.sheetDetailId;
      this.exammarkPointList = this.exammarkPointList.map((item) => {
        item.type == this.scoreTypeValue;
        return item;
      });
      setStore({
        name: "exammarkPointItem",
        type: 1,
        content: this.exammarkPointList,
      });
      setStore({
        name: "question",
        type: 1,
        content: indexQuestion,
      });
      setStore({
        name: "tId",
        type: 1,
        content: 1,
      });
      setStore({
        name: "questionId",
        type: 1,
        content: indexQuestion.sheetDetailId,
      });

      this.$router.push({
        name: "ExamTaskRules",
        query: this.$route.query,
      });
    },
    setRule() {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "系统将 "),
          h("span", { style: "color: #2474ED" }, "清除全部分项"),
          h("span", null, " ，该题将设置为 一键给分 默认步长为1。"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            // type 1 评分规则 2 给分方式
            let obj = JSON.parse(JSON.stringify(this.indexQuestion));
            obj.markScore = 1;
            obj.markType = 2;
            let data = {
              examPaperId: this.indexQuestion.examPaperId,
              type: 2,
              ruleScore: [obj],
            };
            this.exammarkRuleScore(data, done, instance);
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    exammarkRuleScore(data, done, instance) {
      exammarkRuleScore(data)
        .then(() => {
          this.$message({
            showClose: true,
            type: "success",
            message: "设置成功",
          });
          done();
          this.dialogVisible = false;
          instance.confirmButtonLoading = false;
          this.$parent.exammarkTeacherList();
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },

    checkItem(item) {
      //   console.log(item);
      if (item.step == 1) {
        if (item.oldStep) {
          item.markScore = item.oldStep;
        } else {
          item.markScore = 1;
        }
      } else {
        if (!Number(item.score)) {
          this.$message({
            message: "请先设置有效分数",
            type: "warning",
            showClose: true,
          });
          return;
        }
        if (
          !Number(item.stepValue) ||
          Number(item.stepValue) > Number(item.score)
        ) {
          this.$message({
            message: "请输入有效步长",
            type: "warning",
            showClose: true,
          });
          return;
        }
        let arr = [];
        let number = "";

        number = Number(item.score);
        for (let i = 0; i <= parseInt(number / item.stepValue); i++) {
          arr.push((i * (item.stepValue * 10)) / 10);
        }
        if (item.score % item.stepValue > 0) {
          arr.push(item.score);
        }
        // console.log(arr);
        item.markScore = arr.join(",");
      }
    },
    init(item) {
      this.dialogVisible = true;
      this.indexQuestion = item;
      this.scoreTypeValue = item.pointType;
      this.exammarkPoint();
    },
    submit() {
      let type = false;
      let step = false;
      let stepArr = [];
      let nameArr = [];

      let errorScoreItem = [];
      let str = "";
      let score = 0;

      let noPoints = [];
      this.exammarkPointList.map((index, i) => {
        index.sheetDetailId = this.indexQuestion.sheetDetailId;
        // console.log(index.markScore);
        index.type = this.scoreTypeValue;
        if (index.markScore) {
          index.markScore = index.markScore.toString();
          index.markScore = index.markScore.replaceAll("，", ",");
        }

        if (!index.points.width) {
          noPoints.push(i);
        }
        if (!index.name) {
          type = true;
          nameArr.push(i + 1);
        }
        score = score + Number(index.score);
        if (index.step == 2) {
          if (!index.markScore) {
            step = true;
            stepArr.push(i + 1);
            return;
          }
          let re = /^\d+(?=\.{0,1}\d+$|$)/;
          let markScoreList = index.markScore.split(",");
          markScoreList.forEach((item) => {
            // console.log(re.test(item));
            if (!re.test(item) || item > Number(index.score)) {
              step = true;
              stepArr.push(i + 1);
            }
          });
        } else {
          if (Number(index.step) > Number(index.score)) {
            step = true;
            stepArr.push(i + 1);
          }
        }
      });

      if (nameArr.length > 0) {
        this.$message({
          message: `请输入第${nameArr.join(",")}项名称`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (noPoints.length > 0 && this.scoreType == 1) {
        this.$message({
          message: `请设置第${noPoints.join(",")}项打分区域`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.indexQuestion.questionScore != score) {
        this.$message({
          message: "分项分数之和必须等于小题总分",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (errorScoreItem.length > 0) {
        this.$message({
          message: `第${errorScoreItem.join(",")}项误差分大于小题总分`,
          type: "warning",
          showClose: true,
        });
        return;
      }

      if (type) {
        this.$message({
          message: str,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (step) {
        this.$message({
          message: `第${stepArr.join(",")}项步长无效`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.saveExammarkPoint();
    },
    saveExammarkPoint() {
      this.btnLoading = true;
      let array = JSON.parse(JSON.stringify(this.exammarkPointList));
      array = array.map((item) => {
        if (item.type == 1) {
          item.points = JSON.stringify(item.points);
        } else {
          item.points = "";
        }
        if (item.step == 1) {
          item.markScore = item.stepValue;
        }

        return item;
      });
      saveExammarkPoint(array)
        .then(() => {
          this.$message({
            showClose: true,
            type: "success",
            message: "设置成功",
          });
          this.$parent.exammarkTeacherList();
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    exammarkPoint() {
      let data = {
        examPaperId: this.indexQuestion.examPaperId,
        sheetDetailId: this.indexQuestion.sheetDetailId,
      };
      this.loading = true;
      exammarkPoint(data)
        .then((res) => {
          let array = JSON.parse(JSON.stringify(res.data.data));
          array = array.map((item) => {
            item.tId = item.id;
            if (item.type == 1) {
              item.points = JSON.parse(item.points);
            }

            if (item.step == 2) {
              item.stepValue = 1;
              // item.markScore = "";
            } else {
              item.stepValue = Number(item.markScore);
            }
            return item;
          });
          this.exammarkPointList = array;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.scoreMethod {
  .warning-tips {
    padding: 10px;
    background: #fff5f0;
    border: 1px dashed #ffb692;
    border-radius: 4px;
    margin-top: 24px;
    color: #343231;
    span {
      display: block;
      padding-left: 20px;
      //   &:first-child {
      position: relative;
      &::after {
        content: "!";
        position: absolute;
        left: 0;
        top: 4px;
        width: 12px;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0;
        background: #e5611f;
        border-radius: 50%;
      }
      //   }
    }
  }
  .question-item {
    padding: 14px;
    border: 1px solid #dee0e7;
    border-bottom: none;
    .check-type {
      margin: 18px 0;
    }
    .tips {
      padding: 6px 8px;
      background: #2474ed;
      border-radius: 4px;
      color: #ffffff;
      margin-right: 18px;
    }
    .score-box {
      .el-input {
        width: 80px;
      }
    }
    &:last-child {
      border-bottom: 1px solid #dee0e7;
    }
  }
  .el-radio {
    margin-bottom: 0;
  }
  .title {
    margin-bottom: 18px;

    span {
      color: #3e4551;
    }
    .question-name {
      font-weight: 500;
      color: #0a1119;
      margin-right: 18px;
    }
  }
}
</style>
