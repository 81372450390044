<template>
  <div class="ExamCorrectTask">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      router-url="examlist"
      :query="{ examId: $route.query.examId }"
      :name="examInformation.name + ' / '"
    >
    </ExamDetailsHeader>

    <div class="set-up-rules">
      <div
        class="rules-title"
        :class="{
          'rule-type': examInformationPaper.examType == 1,
        }"
      >
        <div class="rules-left-box">
          （上传正常卡人数/考生人数：<span class="span-err">{{
            sheetCount.sheetCount
          }}</span>
          / {{ sheetCount.examStudentCount }}）
          <em @click="exammarkExportOutline">
            <!-- <img src="@/static/exam/export.png" alt="" /> -->
            导出阅卷分工提纲
          </em>
          <span
            v-if="examInformationPaper.examType == 1"
            class="rules-right-tips"
            @click="dialogVisibleScore = true"
          >
            重新计算平均分
          </span>
          <span class="rules-right-tips" @click="dialogVisible = true">
            多评规则
          </span>
          <el-switch
            v-model="allowRepeat"
            style="margin-left: 24px"
            active-text="允许重复批阅"
            inactive-text=""
            @change="putRepeat"
          >
          </el-switch>
        </div>
        <div v-if="examInformationPaper.examType == 0" class="btn-list">
          <el-button
            type="primary"
            plain
            :loading="markTypeLoading"
            @click="exammarkMarkType()"
            >按班级批阅</el-button
          >
          <!-- <el-button
          type="primary"
          plain
          @click="calculateTackDialogVisible = true"
          >快捷计算任务量</el-button
        > -->
          <el-button type="primary" plain @click="goBindQuestuion"
            >设置绑定批阅</el-button
          >
          <el-button type="primary" plain @click="setScore"
            >设置分项给分</el-button
          >
          <el-button type="primary" plain @click="goChoose()"
            >选做题处理</el-button
          >
          <el-button type="primary" plain @click="dialogVisiblePaper = true"
            >导入阅卷分工</el-button
          >

          <el-button
            type="success"
            plain
            :loading="exportLoding"
            @click="exammarkExportOutMark"
            >导出阅卷分工</el-button
          >
        </div>
      </div>

      <el-table
        v-loading="listLoading"
        :data="exammarkList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="questionNum"
          label="题目名称"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <template
              v-if="
                scope.row.MarkInfoPoint && scope.row.MarkInfoPoint.length > 0
              "
            >
              <el-popover
                placement="right"
                trigger="click"
                class="el-popover-tips"
              >
                <div>
                  <p>分项给分</p>
                  <p v-for="(v, i) in scope.row.MarkInfoPoint" :key="i">
                    {{ v.name }}：{{ v.score }}分
                  </p>
                </div>
                <el-button slot="reference" type="text">
                  <template v-if="scope.row.isOption == 1">选做题</template>
                  <template v-else>主观题</template>{{ scope.row.questionNum }}
                </el-button>
              </el-popover>
            </template>
            <template v-else>
              <template v-if="scope.row.isOption == 1">选做题</template>
              <template v-else>主观题</template>{{ scope.row.questionNum }}
            </template>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center">
          <template #header>
            评分规则
            <el-button
              v-if="exammarkList.length > 0"
              class="set-btn"
              :disabled="getListDis()"
              plain
              icon="el-icon-setting"
              @click="setScoreRules(1)"
              >批量设置</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              v-if="$route.name == 'ExamCorrectTask'"
              type="text"
              class="set-list-btn"
              @click="
                setIndexQuestionRules(scope.row, 'scoreRolesdialogVisible')
              "
            >
              {{ scope.row.ruleScore | setName(scoreRulesList) }}</el-button
            >
            <template v-else>
              {{ scope.row.ruleScore | setName(scoreRulesList) }}
            </template>
            <div v-if="scope.row.ruleScore != 1">
              误差分：{{ scope.row.errorScore }}分
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="229">
          <template #header>
            给分方式
            <i
              class="el-icon-question set-score"
              @click="scoreMethodDialogVisible = true"
            ></i>
            <el-button
              v-if="exammarkList.length > 0"
              class="set-btn"
              plain
              :disabled="getListDis()"
              icon="el-icon-setting"
              @click="setScoreRules(2)"
              >批量设置</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              v-if="$route.name == 'ExamCorrectTask'"
              type="text"
              class="set-list-btn"
              @click="setIndexQuestionRules(scope.row, 'scoreSetDialogVisible')"
            >
              {{ scope.row.markType | setName(scoreMethod)
              }}<template v-if="scope.row.markType == 3"
                >-{{ scope.row.pointType | setName(scoreTypeItem) }}</template
              >
            </el-button>
            <template v-else
              >{{ scope.row.markType | setName(scoreMethod) }}
            </template>
            <template
              v-if="
                scope.row.markStep == 1 &&
                scope.row.markScore &&
                scope.row.markType != 3
              "
            >
              步长：{{ scope.row.markScore }}
            </template>
            <!-- <template v-else class="score-title">给分点约束：</template> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="questionNum"
          label="已设置阅卷教师"
          align="center"
          class="tab-teacher-box"
        >
          <template slot-scope="scope">
            <template
              v-if="
                !scope.row.bindQuestionNum ||
                (scope.row.bindQuestionNum &&
                  scope.row.bindQuestionNum
                    .split(',')
                    .indexOf(scope.row.questionNum) == 0)
              "
            >
              <div class="tab-teacher-list-box">
                <div>
                  <div
                    slot="reference"
                    class="warning-box"
                    :class="{ 'end-num': getIndexClass(scope.row, 1) }"
                    @click="scope.row.visible = !scope.row.visible"
                  >
                    <template v-if="scope.row.ruleScore != 1"
                      >一、二评
                    </template>
                    <template v-else
                      >{{ scope.row.ruleScore | setName(scoreRulesList) }}
                    </template>
                    (
                    <span class="null-num">{{
                      scope.row | setIndexTask(1)
                    }}</span>
                    / {{ scope.row | setTasks(1) }} )
                  </div>
                  <!-- 判断下没有任务 就是显示提示 -->
                  <div
                    v-if="
                      scope.row.teacherList &&
                      scope.row.teacherList.filter(
                        (item) => item.type == 1 || item.type == 2
                      ).length == 0 &&
                      showAllTips
                    "
                    class="task-box"
                  >
                    {{ scope.row | getCnt(1) }}
                  </div>
                  <!-- </el-popover> -->
                  <template v-for="(v, i) in scope.row.teacherList">
                    <!-- {{ v.type }} -->
                    <span
                      v-if="v.type == 1 || v.type == 2"
                      :key="i"
                      class="teacher-list-name"
                    >
                      {{ v.name }} ({{ v.workload }})
                    </span>
                  </template>
                </div>
                <el-button
                  type="text"
                  class="tab-set-btn"
                  @click="showTeacherList(1, scope.row)"
                  >设置</el-button
                >
              </div>
              <div v-if="scope.row.ruleScore == 3" class="tab-teacher-list-box">
                <div>
                  <div
                    class="warning-box"
                    plain
                    :class="{ 'end-num': getIndexClass(scope.row, 3) }"
                  >
                    三评(
                    <span class="null-num">{{
                      scope.row | setIndexTask(3)
                    }}</span>
                    / {{ scope.row | setTasks(3) }} )
                  </div>
                  <div
                    v-if="
                      scope.row.teacherList &&
                      scope.row.teacherList.filter((item) => item.type == 3)
                        .length == 0 &&
                      showAllTips
                    "
                    class="task-box"
                  >
                    {{ scope.row | getCnt(3) }}
                  </div>
                  <template v-for="(v, i) in scope.row.teacherList">
                    <span v-if="v.type == 3" :key="i" class="teacher-list-name">
                      {{ v.name }} ({{ v.workload }})
                    </span>
                  </template>
                </div>
                <el-button
                  type="text"
                  class="tab-set-btn"
                  @click="showTeacherList(3, scope.row)"
                >
                  设置
                </el-button>
              </div>
              <div v-if="scope.row.ruleScore != 1" class="tab-teacher-list-box">
                <div>
                  <div
                    class="warning-box"
                    :class="{
                      'end-num':
                        scope.row.teacherList &&
                        scope.row.teacherList.filter((item) => item.type == 4)
                          .length > 0,
                    }"
                  >
                    仲裁教师
                  </div>
                  <div
                    v-if="
                      scope.row.teacherList &&
                      scope.row.teacherList.filter((item) => item.type == 4)
                        .length == 0
                    "
                    class="zc-task"
                  >
                    尚未设置仲裁老师
                  </div>
                  <template v-for="(v, i) in scope.row.teacherList">
                    <span v-if="v.type == 4" :key="i" class="teacher-list-name">
                      {{ v.name }}
                    </span>
                  </template>
                </div>
                <el-button
                  type="text"
                  class="tab-set-btn"
                  @click="showTeacherList(4, scope.row)"
                  >设置</el-button
                >
              </div>
            </template>
            <template v-else>
              <template v-if="scope.row.bindQuestionNum">
                已和{{ scope.row.bindQuestionNum.split(",")[0] }}题设置绑定批阅
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center">
          <template slot="header" slot-scope="scope">
            操作
            <el-button
              class="set-btn"
              plain
              icon="el-icon-setting"
              @click="clearTeacherList(scope.row)"
              >清空阅卷老师</el-button
            >
          </template>
          <template slot-scope="scope">
            <template
              v-if="
                !scope.row.bindQuestionNum ||
                (scope.row.bindQuestionNum &&
                  scope.row.bindQuestionNum
                    .split(',')
                    .indexOf(scope.row.questionNum) == 0)
              "
            >
              <el-button
                type="text"
                class="set-list-btn"
                @click="
                  setIndexQuestionRules(scope.row, 'copyTeacherdialogVisible')
                "
              >
                复制</el-button
              >
            </template>
            <template v-else> - </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      title="重新计算平均分"
      :visible.sync="dialogVisibleScore"
      width="784px"
    >
      <div class="model-teacher-box">
        <div class="model-tips">
          <div>!</div>
          <span>注意</span>
          <p>
            1、功能适用于：多评试题阅卷过程中或完毕后，需要修改试题平均分计算规则，并按新规则重新计算已阅试卷的最终分；
          </p>
          <p>
            2、此功能与阅卷分工页面修改进位方式，互相影响，只需一处修改，则会生效；
          </p>
          <p>
            3、执行此功能，需要重新计算每个考生的得分，考生越多，则执行越慢，请耐心等待；
          </p>
        </div>
        <!-- exammarkList -->
        <div class="check-box">
          <div>
            选择题目：
            <el-select v-model="indexQuestionNum" placeholder="请选择">
              <template v-for="(item, key) in exammarkList">
                <el-option
                  v-if="item.ruleScore != 1"
                  :key="key"
                  :label="item.questionNum"
                  :value="item.questionNum"
                >
                </el-option>
              </template>
            </el-select>
          </div>

          <div>
            修改进位方式：
            <el-select
              v-model="indexScaleType"
              placeholder="请选择"
              style="width: 130px; margin-right: 22px"
              @change="checkScaleType"
            >
              <el-option
                v-for="item in decimalDigits"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="indexCarryType"
              placeholder="请选择"
              style="width: 130px"
            >
              <template v-for="item in digitsMethod">
                <el-option
                  v-if="getshow(item.value, 1)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </template>
            </el-select>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleScore = false">取消</el-button>
        <el-button type="primary" @click="checkQuestion">
          保存并重新计算已阅得分
        </el-button>
      </span>
    </el-dialog>

    <el-dialog title="多评规则" :visible.sync="dialogVisible" width="873px">
      <div class="rules-tips">
        <p>（一）多评流程：</p>
        <span>[单评]</span>.一张试卷只由一个阅卷教师批阅，所给分数为最终分。
        <br />
        <span>[双评+仲裁]</span
        >.系统将同一考生的同一题目分发给两位不同的评卷教师（一评和二评），各自独立评分。
        在此之前，需要针对每道题目设置一个“误差分”，当一评二评分数之差在“误差分”范围内时，系统将自动取两人的平均分作为该题的最终得分。
        如果超出误差分范围，则由仲裁老师直接打分，作为该题的得分。
        <br />
        <span>[2+1+仲裁]</span
        >.系统将同一考生的同一题目分发给两位不同的评卷教师（一评和二评），各自独立评分。
        <br />
        在此之前，需要针对每道题目设置一个“误差分”，当一评二评分数之差在“误差分”范围内时，系统将自动取两人的平均分作为该题的最终得分。
        如果超出误差分范围，系统将该考生答题信息随机分发给第三位评卷教师评分，称之“三评”，三评计分共分三种情形：
        <br />
        •
        若三评打出的分数仅与一评或二评的分数差值在误差分范围内，系统自动取三评和一评或二评的平均分作为该题最终得分；
        <br />
        •
        若三评的评分与一评和二评的分数差值都在误差范围内，系统将取差值较小的两个分数的平均分作为最终得分，若差值相等则取三个分数中较高的两个分数的平均分；
        <br />
        •
        若三评的评分与一评或二评的分数差值均大于误差分，则由仲裁老师直接打分，作为该题的得分。
        <p style="margin-top: 24px">
          （二）平均分计算规则： 进位方式中取0.5就高、取0.5就低的规则：
        </p>

        • 取0.5就高：>0.5进位1，＜0.5变为0.5，举例：1.75⇒2,1.25⇒1.5 <br />
        • 取0.5就低：>0.5变为0.5，＜0.5变为0，举例：1.75⇒1.5,1.25⇒1
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">我知道了</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button> -->
      </span>
    </el-dialog>
    <!-- 评分规则设置 -->
    <el-dialog
      title="评分规则设置"
      :close-on-click-modal="false"
      :visible.sync="scoreRolesdialogVisible"
      width="600px"
    >
      <div class="score-box">
        <div>题目名称： {{ indexQuestion.questionNum }}</div>
        <div>
          评分规则：
          <el-radio-group v-model="indexQuestion.ruleScore">
            <el-radio
              v-for="(v, i) in scoreRulesList"
              :key="i"
              :label="v.value"
              :disabled="setcheckTeacher(v)"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <template v-if="indexQuestion.ruleScore != 1">
          <div>
            误差分大于：
            <el-input
              v-model="indexQuestion.errorScore"
              style="width: 130px"
              placeholder="请输入分数"
              oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
              @blur="indexQuestion.errorScore = $event.target.value"
            ></el-input>
            <span class="score"
              >(满分：{{ indexQuestion.questionScore }}分)</span
            >
          </div>
          <div>
            平均分计算规则：小数位数：
            <el-select
              v-model="indexQuestion.scaleType"
              placeholder="请选择"
              style="width: 130px; margin-right: 22px"
              @change="checkScale"
            >
              <el-option
                v-for="item in decimalDigits"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            进位方式：<el-select
              v-model="indexQuestion.carryType"
              placeholder="请选择"
              style="width: 130px"
            >
              <template v-for="item in digitsMethod">
                <el-option
                  v-if="getshow(item.value)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </template>
            </el-select>
          </div>
        </template>
        <p v-if="indexQuestion.ruleScore == 1" class="tips">
          <span>!</span
          ><span>【单评】</span
          >：一张试卷只由一个阅卷教师批阅，所给分数为最终分。
        </p>
        <p v-if="indexQuestion.ruleScore == 2" class="tips">
          <span>!</span
          ><span>【双评+仲裁】</span
          >：系统将同一考生的同一题目分发给两位不同的评卷教师（一评和二评），各自独立评分。
          在此之前，需要针对每道题目设置一个“误差分”，当一评二评分数之差在“误差分”范围内时，系统将自动取两人的平均分作为该题的最终得分。
          如果超出误差分范围，则由仲裁老师直接打分，作为该题的得分。
          <br />
          <br />
          <br />
          <span>【平均分计算规则】</span
          >：进位方式中取0.5就高、取0.5就低的规则：
          <br />
          • 取0.5就高：>0.5进位1，&lt;0.5变为0.5，举例：1.75⇒2,1.25⇒1.5
          <br />
          • 取0.5就低：>0.5变为0.5，&lt;0.5变为0，举例：1.75⇒1.5,1.25⇒1
        </p>
        <p v-if="indexQuestion.ruleScore == 3" class="tips">
          <span>!</span
          ><span>【2+1+仲裁】</span
          >系统将同一考生的同一题目分发给两位不同的评卷教师（一评和二评），各自独立评分。在此之前，需要针对每道题目设置一个“误差分”，当一评二评分数之差在“误差分”范围内时，系统将自动取两人的平均分作为该题的最终得分。如果超出误差分范围，系统将该考生答题信息随机分发给第三位评卷教师评分，称之“三评”，三评计分共分三种情形：
          <br />
          <br />
          <br />
          <br />
          •
          若三评打出的分数仅与一评或二评的分数差值在误差分范围内，系统自动取三评和一评或二评的平均分作为该题最终得分；
          <br />
          •
          若三评的评分与一评和二评的分数差值都在误差范围内，系统将取差值较小的两个分数的平均分作为最终得分，若差值相等则取三个分数中较高的两个分数的平均分；
          <br />
          •
          若三评的评分与一评或二评的分数差值均大于误差分，则由仲裁老师直接打分，作为该题的得分。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreRolesdialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitScore">
          保 存
        </el-button>
      </span>
    </el-dialog>
    <!-- 查看给分方式 -->
    <el-dialog
      title="查看给分方式"
      :visible.sync="scoreMethodDialogVisible"
      width="30%"
      class="score-model"
    >
      <div>
        <p>计算给分</p>
        <img src="@/assets/imgs/exam/comImg.png" alt="" />
        <p>一键给分</p>
        <img src="@/assets/imgs/exam/onekeyImg.png" alt="" />
      </div>
    </el-dialog>
    <!-- 给分方式设置 -->
    <el-dialog
      title="给分方式设置"
      :visible.sync="scoreSetDialogVisible"
      width="586px"
    >
      <div class="score-set-box">
        <div class="tips">
          <span>!</span>
          注意：设置步长或自定义给分点可约束教师给分！
        </div>
        <div>
          <span class="score-title">题目名称：</span>
          {{ indexQuestion.questionNum }}
        </div>
        <!-- <div class="score-set-box-item">
          <span class="score-title"> 给分方式：</span>
          <el-radio-group v-model="indexQuestion.markType">
            <el-radio v-for="(v, i) in scoreMethod" :key="i" :label="v.value">{{
              v.label
            }}</el-radio>
          </el-radio-group>
        </div> -->
        <div class="score-set-box-item">
          <span class="score-title">给分步长：</span>
          <el-radio-group
            v-model="indexQuestion.markStep"
            @change="markStepChange"
          >
            <el-radio v-for="(v, i) in stepSize" :key="i" :label="v.value">{{
              v.label
            }}</el-radio>
          </el-radio-group>
          <span class="score">(满分：{{ indexQuestion.questionScore }}分)</span>
        </div>
        <div>
          <span v-if="indexQuestion.markStep == 1" class="score-title">
            步长：
          </span>
          <span v-else class="score-title">给分点约束：</span>
          <el-input
            v-if="indexQuestion.markStep == 1"
            v-model="indexQuestion.markScore"
            placeholder="请输入"
            style="width: 200px"
            oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
            @blur="indexQuestion.markScore = $event.target.value"
          ></el-input>
          <el-input
            v-if="indexQuestion.markStep == 2"
            v-model="indexQuestion.markScore"
            placeholder="请输入"
            style="width: 200px"
            oninput="value=value.replace(/[^0-9.,，]/g,'')"
            @blur="indexQuestion.markScore = $event.target.value"
          ></el-input>
        </div>
        <div v-if="examInformationPaper.examType == 1">
          <span class="time-title"><span>!</span>最低阅卷时间：</span>
          <el-input
            v-model="indexQuestion.markTime"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="请输入"
            style="width: 140px"
            @blur="indexQuestion.markTime = $event.target.value"
          >
          </el-input>
          秒
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreSetDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="submitScoreSet()"
        >
          保 存
        </el-button>
      </span>
    </el-dialog>
    <!-- 添加阅卷教师 -->
    <el-dialog
      :title="`添加${indexSubjectName}阅卷教师`"
      :visible.sync="teacherDialogVisible"
      :close-on-click-modal="false"
      width="1138px"
    >
      <div class="set-teacher-box">
        <div class="teacher-search-box">
          <div v-if="examInformationPaper.examType == 1" class="search-item">
            学校：
            <el-select
              v-model="schoolId"
              placeholder="请选择学段"
              clearable
              @change="selectChange"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            学段：
            <el-select
              v-model="modelSearch.level"
              placeholder="请选择学段"
              clearable
              @change="levelChange"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <!-- yearHignOptions yearMiddleOptions -->
            年级：
            <el-select
              v-model="modelSearch.year"
              placeholder="请选择年级"
              clearable
              @change="gradeChange"
            >
              <el-option
                v-for="(item, key) in yearList"
                :key="key"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-select
              v-else
              v-model="modelSearch.year"
              clearable
              placeholder="请选择年级"
              @change="gradeChange"
            >
              <el-option
                v-for="(item, key) in yearHignOptions"
                :key="key"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select> -->
          </div>
          <!-- <div class="search-item ts-item">
            班级：
            <el-select
              v-model="modelSearch.classNum"
              clearable
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in classOptions"
                :key="item.id"
                :label="item.classNum"
                :value="item.classNum"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="search-item">
            科目：
            <el-select
              v-model="modelSearch.subjectId"
              clearable
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-input
            v-model="modelSearch.name"
            class="search-input"
            :class="{ 'school-list': examInformationPaper.examType == 1 }"
            placeholder="请输入搜索内容"
            @keyup.enter.native="schoolteacherlistv2"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="schoolteacherlistv2"
            >
            </i
          ></el-input>

          <div class="check-teacher-list">
            <div class="check-header">
              <template v-if="indexTeacherType != 4">
                <el-button type="text" @click="changeAll(1)"> 全选 </el-button>
                <el-button type="text" @click="changeAll(2)"> 取消 </el-button>
              </template>
              <template v-else>老师列表</template>
            </div>
            <div class="check-list edit-scroll-style">
              <el-checkbox-group
                v-model="checkTeacherList"
                @change="setMarkingList"
              >
                <el-checkbox
                  v-for="(v, i) in teacherList"
                  :key="i"
                  :label="v.teacherId"
                >
                  {{ v.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="teacher-list-box">
          <div class="teacher-list-header">
            <template v-if="indexTeacherType == 1 || indexTeacherType == 2">
              <template v-if="indexQuestion.ruleScore != 1">一、二评</template>
              <template v-else> 单评</template>
              <span style="font-size: 12px; color: red; margin-left: 16px">
                若教师数量大于任务量，最低会将老师的任务设为1
              </span>
            </template>

            <template v-if="indexTeacherType == 3">
              三评
              <span style="font-size: 12px; color: red; margin-left: 16px">
                若教师数量大于任务量，最低会将老师的任务设为1
              </span>
            </template>
            <template v-if="indexTeacherType == 4"> 仲裁 </template>
          </div>
          <div class="teacher-cnt">
            <div>
              <template v-if="indexTeacherType != 4">
                批量设置任务量：<el-input
                  v-model="teacherNumber"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="teacherNumber = $event.target.value"
                ></el-input>
                <el-button type="primary" @click="setTeacherCnt"
                  >设置</el-button
                >
              </template>
            </div>
            <div class="teacher-btn-cnt">
              <template v-if="indexTeacherType != 4">
                <el-button
                  type="text"
                  @click="
                    (averageDialogVisible = true) &&
                      (averageVal = sheetCount.sheetCount)
                  "
                  >平均分配任务量</el-button
                >
              </template>
              <el-button type="text" @click="clearAll">清空</el-button>
            </div>
          </div>
          <div class="teacher-list-number-box edit-scroll-style">
            <template v-for="(v, i) in markingTeacher">
              <div :key="i" class="teacher-item">
                {{ v.name }}
                <el-input
                  v-if="indexTeacherType != 4"
                  v-model="v.workload"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder=""
                  @blur="v.workload = $event.target.value"
                ></el-input>
                <i
                  class="el-icon-delete"
                  style="margin-right: 4px"
                  @click="delTeacher(v.teacherId)"
                ></i>
                <template v-if="v.teacherHasMark">
                  已批阅：{{ v.teacherHasMark }}
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="teacherDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="submitTeacherList"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 复制阅卷老师 -->
    <el-dialog
      title="复制"
      :visible.sync="copyTeacherdialogVisible"
      width="650px"
    >
      <div class="model-teacher-box">
        <p>复制{{ indexQuestion.questionNum }}题到以下勾选的小题中</p>
        <!-- <el-checkbox-group v-model="checkList" @change="checkListChange"> -->
        <div class="model-teacher-box-btn">
          <el-button type="text" @click="checkListChange('checkAll')"
            >全选</el-button
          >
          <el-button type="text" @click="checkListChange('checkFalse')"
            >反选</el-button
          >
          <el-button type="text" @click="checkListChange('checkCancel')"
            >取消</el-button
          >
        </div>
        <div>
          <el-checkbox-group v-model="questionCheckList">
            <template v-for="(v, i) in exammarkList">
              <el-checkbox
                v-if="
                  indexQuestion.sheetDetailId != v.sheetDetailId &&
                  v.teacherList.length == 0
                "
                :key="i"
                :label="v.sheetDetailId"
                >{{ v.questionNum }}</el-checkbox
              >
            </template>
          </el-checkbox-group>
        </div>
        <div class="model-teacher-type">
          请选择需要复制的教师类型：
          <el-checkbox-group
            v-model="teacherTypeList"
            @change="checkTeacherTypeList"
          >
            <template v-if="indexQuestion.ruleScore == 1">
              <el-checkbox :label="1">单评教师</el-checkbox>
            </template>
            <template v-else>
              <el-checkbox :label="1">一、二评教师</el-checkbox>
              <el-checkbox :label="4">仲裁教师</el-checkbox>
              <el-checkbox :label="3">三评教师</el-checkbox>
            </template>
            <!-- 1: 一、二评教师,2:仲裁教师,3:三评教师 -->
          </el-checkbox-group>
        </div>
        <div class="model-tips">
          <div>!</div>
          <span>注意</span>
          ：1.教师正在批阅中的试题，不允许勾选哦！2.复制需先清空原有设置后进行复制
          3.复制单评或一二评教师时，一组绑定批阅的试题需要全部勾选
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="copyTeacherdialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="exammarkCopyTeacher"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 快捷计算任务量 -->
    <el-dialog
      title="快捷计算任务量"
      :visible.sync="calculateTackDialogVisible"
      width="694px"
    >
      <div class="task-num">
        <div>将选中小题按以下任意一种方式重新分配！</div>
        <div class="task-num-margin">
          选择分配方式：
          <el-radio v-model="calculateType" :label="1"
            >按有效卷重新分配</el-radio
          >
          <el-radio v-model="calculateType" :label="2"
            >按考生人数重新分配</el-radio
          >
        </div>
        <div>
          <div>
            <el-button type="text" @click="calculateChange(1)">全选</el-button>
            |
            <el-button
              type="text"
              style="margin-left: 0"
              @click="calculateChange(2)"
              >反选</el-button
            >
            |
            <el-button
              type="text"
              style="margin-left: 0"
              @click="calculateChange(3)"
              >取消</el-button
            >
          </div>
          <!-- <el-checkbox-group
            v-model="calculateCheckType"
            @change="calculateChange"
          >
            <el-checkbox :label="1">全选</el-checkbox>
            <el-checkbox :label="2">反选</el-checkbox>
            <el-checkbox :label="3">取消</el-checkbox>
          </el-checkbox-group> -->
        </div>
        <div class="check-question-list">
          <!-- exammarkList -->
          <el-checkbox-group v-model="calculateQuestion">
            <el-checkbox
              v-for="(v, i) in exammarkList"
              :key="i"
              :label="v.questionNum"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="calculateTackDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="submitCalculateType"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 导入阅卷分工 -->
    <el-dialog
      title="导入阅卷分工"
      :visible.sync="dialogVisiblePaper"
      width="720px"
    >
      <div class="examination-tips">
        <div>1、只能导入单评教师或一、二评教师。</div>
        <div>
          2、如果小题导入的教师与原有的教师相同，则会根据Excel内容修改任务量。
        </div>
        <div>
          3、如果填写了教师用户名则优先按照用户名匹配教师，未填写用户名则按照教师姓名进行匹配教师。
        </div>
        <div>4、如果有重名教师，建议填写教师用户名加以区分。</div>
        <div>
          5、如果教师的已阅量大于分配的任务量，则将教师任务量设置为已阅量。
        </div>
        <div>
          6、一行设置一个教师，如设置多个教师，则将小题复制多行，题目列不能为空。
        </div>
        <div>
          7、绑定批阅的试题，只需要导入主绑小题的单评教师或一、二评教师。
        </div>
        <div>8、导入文件中，只要存在一条数据不合法，则所有数据都无法导入。</div>
      </div>
      <div class="down-file">
        当前仅支持excel文件，<el-button type="text" @click="downFile()"
          >下载Excel 模版</el-button
        >
      </div>
      <el-upload
        ref="uploadDemo"
        class="upload-demo"
        drag
        :action="examImportURL"
        :headers="uploadHeaders"
        accept=".xlsx,.xls"
        :before-upload="handleImportBefore"
        :on-success="handleImportOnSuccess"
        :on-error="handleImportOnError"
        :on-progress="handleImportOnProgress"
        multiple
        :data="xlsData"
        :auto-upload="false"
        :file-list="fileList"
        :limit="1"
      >
        <!-- <i class="el-icon-upload"></i> -->
        <div class="el-upload__text">请将文件拖放到此处或点击上传</div>
        <!-- <div slot="tip" class="el-upload__tip">
          只能上传jpg/png文件，且不超过500kb
        </div> -->
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePaper = false">取 消</el-button>
        <el-button type="primary" @click="upLoadTeacher()"> 导 入 </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="平均分配任务量"
      :visible.sync="averageDialogVisible"
      width="466px"
      class="average-dialogVisible"
      :close-on-click-modal="false"
    >
      <div style="margin-bottom: 12px">
        上传正常答题卡数量为
        <span class="nums">{{ sheetCount.sheetCount }}</span> 张，考生人数
        <span class="nums">{{ sheetCount.examStudentCount }} </span> 人
      </div>
      <div>
        将
        <el-input
          v-model="averageVal"
          oninput="value=value.replace(/[^\d]/g,'')"
          placeholder="请输入任务量"
          @blur="averageVal = $event.target.value"
        ></el-input>
        张阅卷任务平均分配给所有教师！
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="averageDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setVal">确 定</el-button>
      </span>
    </el-dialog>
    <scoreMethodModel ref="scoreMethodModel"></scoreMethodModel>
  </div>
</template>

<script>
import {
  exammarkTeacherList,
  schoolExamwork,
  exammarkRuleScore,
  exammarkSaveTeacher,
  exammarkExportOutline,
  exammarkClearTeacher,
  exammarkCopyTeacher,
  exammarkMarkType,
  exammarkCalculateWorkload,
  exammarkExportTemplate,
  exammarkExportOutMark,
  exammarkSheet,
  againcalculatescore,
  exammarkExamRepeat,
} from "@/core/api/exam/examCorrectTask";
import {
  scoreRulesList,
  decimalDigits,
  digitsMethod,
  stepSize,
  scoreMethod,
  levelOptions,
  scoreTypeItem,
} from "@/core/util/constdata";
import { schoolteacherlistv2 } from "@/core/api/school/schoolteacher";
import {
  getGradeList,
  getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";
import { fileDownloadByUrl, getSubjectList, getYear } from "@/core/util/util";
import { getStore, removeStore, setStore } from "@/core/util/store";
import { getObj } from "@/core/api/exam/exam";
import ExamDetailsHeader from "@/components/examinationHeader";
import scoreMethodModel from "./components/scoreMethod";
export default {
  name: "ExamCorrectTask",
  components: {
    ExamDetailsHeader,
    scoreMethodModel,
  },
  filters: {
    // 获取当前类型总数
    setIndexTask(item, type) {
      let tasks = 0;
      item.teacherList.forEach((index) => {
        if (type == index.type) {
          tasks = Number(index.workload) + Number(tasks);
        }
      });
      return tasks;
    },
    // 获取任务总数
    setTasks(item, type) {
      let tasks = item.questionCount;

      if (item.ruleScore != 1 && type == 1) {
        tasks = tasks * 2;
      }
      return tasks;
    },
    getCnt(item, type, num) {
      // console.log(item, type);
      let str = "任务量为空";
      // 获取已分配的任务量
      let tasks = 0;
      item.teacherList.forEach((index) => {
        if (type == index.type) {
          tasks = Number(index.workload) + Number(tasks);
        }
        if (type == 1 && index.type == 2) {
          tasks = Number(index.workload) + Number(tasks);
        }
      });
      // 获取任务总数
      let allTasks = item.questionCount;

      if (item.ruleScore != 1 && type == 1) {
        allTasks = allTasks * 2;
      }

      if (tasks < allTasks) {
        str = `尚有  ${allTasks - tasks} 任务量待分配`;
      }
      if (num) {
        str = allTasks - Number(item.schoolWorkload || 0);
      }

      return str;
    },
  },
  data() {
    return {
      examInformation: {
        name: "",
      },
      allowRepeat: false,
      indexQuestionNum: "",
      indexScaleType: 2,
      indexCarryType: 2,

      showAllTips: true,
      listLoading: false,
      // aa: true,
      // 平均分配任务量
      averageVal: 0,
      averageDialogVisible: false,
      // 导入阅卷分工
      dialogVisiblePaper: false,
      examImportURL: "/exam/exammark/import/mark",
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      xlsData: {
        examPaperId: "",
      },
      yearList: getYear(),
      examInformationPaper: {},
      classOptions: [],
      levelOptions: levelOptions(),
      subjectOptions: [],
      scoreMethod: scoreMethod,
      scoreTypeItem: scoreTypeItem,
      stepSize: stepSize,
      scoreRulesList: scoreRulesList,
      digitsMethod: digitsMethod,
      decimalDigits: decimalDigits,
      dialogVisible: false,
      dialogVisibleScore: false,
      // 按人分配
      exammarkList: [],
      // 当前试题
      indexQuestion: {},
      scoreRolesdialogVisible: false,
      btnLoading: false,
      // 给分方式
      scoreMethodDialogVisible: false,
      // 给分方式 设置
      scoreSetDialogVisible: false,
      // 教师列表弹出层 start
      teacherDialogVisible: false,
      indexSubjectName: "",
      modelSearch: {
        level: "",
        subjectId: "",
        year: "",
        roleId: "",
        classNum: "",
        name: "",
      },
      schoolId: "",
      teacherList: [],
      // 选中的教师列表
      checkTeacherList: [],
      yearMiddleOptions: [],
      yearHignOptions: [],
      checkAllList: false,
      indexTeacherType: 1,
      teacherNumber: 0,
      markingTeacher: [],
      markingNum: { teacherId: "", workload: 0, type: 1 },
      // 教师列表弹出层 end
      exportLoding: false,
      // 复制教师 start
      copyTeacherdialogVisible: false,
      checkAll: false,
      checkFalse: false,
      checkCancel: false,
      questionCheckList: [],
      fileList: [],
      teacherTypeList: [],
      // 复制教师 end
      markTypeLoading: false,
      // 快捷计算任务量 start
      calculateTackDialogVisible: false,
      calculateType: 1,
      calculateCheckType: [],
      calculateQuestion: [],
      sheetCount: {
        examStudentCount: 0,
        sheetCount: 0,
      },
      paperList: [],
      schoolList: [],
    };
  },
  created() {
    this.examInformationPaper = this.$route.query;
    this.modelSearch.examPaperId = this.examInformationPaper.paperId;
    this.subjectOptions = getSubjectList();

    this.getSchoolYearList();
    this.indexId = new Date().getTime();
    this.exammarkSheet(this.indexId);

    this.getObj();
    setTimeout(() => {
      this.showAllTips = false;
    }, 10000);
  },
  // destroyed() {
  //   clearInterval(this.getNums);
  // },
  methods: {
    getIndexClass(item) {
      // let sheetCount = this.sheetCount.sheetCount;

      // let tasks = 0;
      // item.teacherList.forEach((index) => {
      //   if (type == index.type) {
      //     tasks = Number(index.workload) + Number(tasks);
      //   }
      // });
      // let tasks1 = item.questionCount;

      // if (item.ruleScore != 1 && type == 1) {
      //   tasks1 = tasks1 * 2;
      //   sheetCount = sheetCount * 2;
      // }
      // returnType = tasks >= tasks1;

      // if (sheetCount > 0 && tasks >= sheetCount) {
      //   returnType = true;
      // }
      // // 添加判断  如果说选做题任务量相等时  就默认给变黑
      // if (item.isOption == 1) {
      //   let list = this.exammarkList.filter(
      //     (index) => index.examQuestionGroup == item.examQuestionGroup
      //   );
      //   let opTask = 0;
      //   list.map((index) => {
      //     index.teacherList.forEach((indexK) => {
      //       if (type == indexK.type) {
      //         opTask = Number(indexK.workload) + Number(opTask);
      //       }
      //     });
      //   });
      //   // console.log(opTask);
      //   if (sheetCount > 0 && opTask >= sheetCount) {
      //     returnType = true;
      //   }
      //   // console.log(list);
      // }
      let returnType = true;
      if (item.workloadRemain > 0) {
        returnType = false;
      }
      return returnType;
    },
    upLoadTeacher() {
      this.$refs.uploadDemo.submit();
    },
    initModelSearch() {
      this.modelSearch.level = this.examInformation.level.toString();
      this.modelSearch.year = this.examInformation.year;
      this.examInformation.paperList.map((item) => {
        if (item.paperId == this.examInformationPaper.paperId) {
          this.modelSearch.subjectId = item.subjectId;
          this.indexSubjectName = item.subjectName;
        }
      });

      // 获取教师列表
      this.schoolteacherlistv2();
    },
    // 修改状态  是否允许重复批阅
    putRepeat() {
      let data = {
        examPaperId: this.examInformationPaper.paperId,
        repeat: this.allowRepeat ? 1 : 0,
      };
      const h = this.$createElement;
      this.$msgbox(
        // `将${this.examInformationPaper.subjectName}学科设为${
        //   this.allowRepeat == 1 ? " 允许 " : " 不允许 "
        // }重复批阅!`,
        // "提示",
        {
          title: "提示",
          message: h("p", null, [
            h("span", null, `将${this.indexSubjectName}设为`),
            h(
              "span",
              { style: "color: red" },
              `${this.allowRepeat == 1 ? "允许" : "不允许"}`
            ),
            h("span", null, `重复批阅!`),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.exammarkExamRepeat(data);
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          this.setallowRepeat();
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    exammarkExamRepeat(data) {
      exammarkExamRepeat(data)
        .then(() => {
          this.paperList.map((item) => {
            if (item.paperId == this.examInformationPaper.paperId) {
              if (this.allowRepeat) {
                item.allowRepeat = 1;
              } else {
                item.allowRepeat = 0;
              }
              this.$message({
                showClose: true,
                message: `已将${item.subjectName}学科设为${
                  this.allowRepeat == 1 ? " 允许 " : " 不允许 "
                }重复批阅!`,
                type: "success",
              });
              // this.allowRepeat
              //   ? (item.allowRepeat = 1)
              //   : (item.allowRepeat = 0);
            }
          });
        })
        .catch(() => {
          this.setallowRepeat();
        });
    },
    setallowRepeat() {
      let indexPaper = this.paperList.filter(
        (item) => item.paperId == this.examInformationPaper.paperId
      );
      // console.log(indexPaper);
      this.allowRepeat = indexPaper[0].allowRepeat == 1 ? true : false;
    },
    checkQuestion() {
      // console.log(this.indexQuestionNum);

      if (!this.indexQuestionNum) {
        this.$message({
          type: "warning",
          message: "请选择试题!",
          showClose: true,
        });
        return;
      }
      let data = {
        questionNum: this.indexQuestionNum,
        scaleType: this.indexScaleType,
        carryType: this.indexCarryType,
        examPaperId: this.examInformationPaper.paperId,
      };
      this.btnLoading = true;
      againcalculatescore(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisibleScore = false;
          this.exammarkTeacherList();
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
      //  indexQuestionNum: "",
      // indexScaleType: 2,
      // indexCarryType: 2,
    },
    getVal(item) {
      // console.log(item);
      let type = false;
      let examStudentCount = Number(item.examStudentCount || 0);
      let schoolWorkload = Number(item.schoolWorkload || 0);
      if (item.ruleScore == 1) {
        if (examStudentCount > schoolWorkload) {
          type = true;
        }
      } else {
        if (examStudentCount * 2 > schoolWorkload) {
          type = true;
        }
      }
      return type;
    },

    goTeacherList() {
      this.$router.push({
        name: "ExamTaskTeacherList",
        query: this.examInformationPaper,
      });
    },
    schoolGroup() {
      this.$router.push({
        name: "ExamTaskSchoolGroup",
        query: this.examInformationPaper,
      });
      // console.log(123456);
    },

    checkSubject(v) {
      this.examInformationPaper.paperId = v.paperId;

      this.indexPaperId = v.paperId;
      clearTimeout(this.getNum);
      // console.log(this.examInformationPaper);
      this.initModelSearch();

      if (v.markType == 2) {
        this.$router.push({
          name: "ExamClassReadCorrectTask",
          query: this.examInformationPaper,
        });
        return;
      }
      let data = { ...this.examInformationPaper };
      data.time = new Date().getTime();

      this.$router.push({
        // name: "ExamClassReadCorrectTask",
        query: data,
      });
      this.$nextTick(() => {
        this.$refs.ExamDetailsHeader.init(this.indexPaperId);
      });
      this.exammarkTeacherList();
      this.setallowRepeat();
      this.indexId = new Date().getTime();
      clearTimeout(this.getNum);
      this.exammarkSheet(this.indexId);
    },
    //获取考试基本信息
    getObj() {
      getObj(this.examInformationPaper.examId).then((res) => {
        this.examInformation = res.data.data;
        this.examInformationPaper.examType = res.data.data.examType;
        this.indexPaperId = this.examInformationPaper.paperId;
        //获取当前年级班级
        this.paperList = res.data.data.paperList.map((item) => {
          if (this.indexPaperId == item.paperId) {
            if (item.markType == 1) {
              this.exammarkTeacherList();
            } else {
              this.$router.push({
                name: "ExamClassReadCorrectTask",
                query: this.examInformationPaper,
              });
            }
          }
          if (item.statusSheet == 1 && item.sheetMarkType != 1) {
            item.label = item.subjectName;
            item.value = item.paperId;
            return item;
          } else {
            return null;
          }
        });

        this.paperList = this.paperList.filter((item) => item);
        this.setallowRepeat();

        this.initModelSearch();
        this.$nextTick(() => {
          this.$refs.ExamDetailsHeader.init(this.indexPaperId);
        });
        // console.log(res);
      });
    },
    checkScale() {
      if (this.indexQuestion.scaleType != 2) {
        if (
          this.indexQuestion.carryType == 4 ||
          this.indexQuestion.carryType == 5
        ) {
          this.indexQuestion.carryType = 3;
        }
      }
    },
    checkScaleType() {
      if (this.indexScaleType != 2) {
        if (this.indexCarryType == 4 || this.indexCarryType == 5) {
          this.indexCarryType = 3;
        }
      }
    },
    //判断是否显示选项
    getshow(val, Qtype) {
      let type = true;
      if (Qtype == 1) {
        if (this.indexScaleType != 2) {
          if (val == 4 || val == 5) {
            type = false;
          }
        }
      } else {
        if (this.indexQuestion.scaleType != 2) {
          if (val == 4 || val == 5) {
            type = false;
          }
        }
      }

      return type;
    },
    setcheckTeacher(val) {
      let returnType = false;
      if (this.indexQuestion.hasMark == 1) {
        if (this.indexQuestion.ruleScore == 1) {
          if (val.value == 1) {
            returnType = false;
          }
          if (val.value == 2) {
            returnType = true;
          }
          if (val.value == 3) {
            returnType = true;
          }
        }
        if (this.indexQuestion.ruleScore == 2) {
          if (val.value == 1) {
            returnType = true;
          }
          if (val.value == 2) {
            returnType = false;
          }
          if (val.value == 3) {
            returnType = false;
          }
        }
        if (this.indexQuestion.ruleScore == 3) {
          if (val.value == 1) {
            returnType = true;
          }
          if (val.value == 2) {
            returnType = false;
          }
          if (val.value == 3) {
            returnType = false;
          }
        }
      }
      // if (val.value == 3 && this.indexQuestion.bindQuestionNum) {
      //   returnType = true;
      // }
      return returnType;
    },
    exammarkSheet(id) {
      // console.log("011111111111");
      // console.log(this.$route.name == "ExamCorrectTask");
      // console.log(this.$route.name == "unionExamClassReadCorrectTask");
      if (
        this.$route.name != "ExamCorrectTask" &&
        this.$route.name != "unionExamClassReadCorrectTask"
      ) {
        return;
      }

      if (this.indexId != id) {
        return;
      }

      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      if (this.examInformationPaper.examType == 1) {
        data.schoolId = this.schoolId || "-1";
        if (this.$route.name != "ExamCorrectTask") {
          data.schoolId = getStore({ name: "school_id" });
        }
      }
      exammarkSheet(data)
        .then((res) => {
          this.sheetCount = res.data.data;

          if (
            res.config.params.examPaperId != this.examInformationPaper.paperId
          ) {
            return;
          }

          this.getNum = setTimeout(() => {
            this.exammarkSheet(this.indexId);
          }, 10000);
        })
        .catch(() => {
          // clearInterval(this.getNums);
        });
    },
    // 导入 start
    handleImportFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    handleImportOnError(err, file, fileList) {
      this.fullscreenLoading = false;
      this.handleImportFileRemove(fileList);
    },
    handleImportBefore(file) {
      if (!(file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
        this.$message.error("只能上传 Excel 文件！");
        return false;
      }
      let is10M = file.size / 1024 / 1024 <= 10;
      if (!is10M) {
        this.$message.error("文件大小不能超过 10 MB！");
        return false;
      }
      const _vm = this;
      _vm.xlsData.examPaperId = this.examInformationPaper.paperId;
      return true;
    },
    handleImportOnProgress() {
      this.importStudentVisible = false;
      this.fullscreenLoading = true;
    },
    // file, fileList
    handleImportOnSuccess(response, file, fileList) {
      // console.log(response);
      if (response.code == 0) {
        if (response.data) {
          this.$message({
            showClose: true,
            type: "error",
            message: "导入失败，已为您下载明细！",
            duration: 2000,
          });
          var link = document.createElement("a");
          link.href = response.data;
          // link.download = file.name;
          // //link.target="_blank";
          link.click();
        } else {
          this.$message({
            showClose: true,
            type: "success",
            message: "导入成功",
            duration: 3000,
          });
          // 优化  1594
          this.dialogVisiblePaper = false;
          this.exammarkTeacherList();
        }
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: response.msg,
          dangerouslyUseHTMLString: true,
          duration: 20000,
        });
      }

      this.handleImportFileRemove(fileList);
    },
    // 导入 end
    // 下载模板
    downFile() {
      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      exammarkExportTemplate(data).then((res) => {
        // console.log(res);
        fileDownloadByUrl(res.data.data.url, res.data.data.name);
      });
    },
    // 重新计算阅卷分工
    submitCalculateType() {
      // console.log(this.calculateQuestion);
      if (this.calculateQuestion.length == 0) {
        this.$message({
          type: "warning",
          message: "请先选择试题!",
          showClose: true,
        });
        return;
      }
      this.btnLoading = true;
      let data = {
        type: this.calculateType,
        examPaperId: this.examInformationPaper.paperId,
        questionNum: this.calculateQuestion.join(","),
      };
      exammarkCalculateWorkload(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "设置成功!",
          });
          this.exammarkTeacherList();
          this.calculateTackDialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 全选  反选  取消
    calculateChange(val) {
      if (val.length > 1) {
        val = [val[val.length - 1]];
      }
      let questionList = [];
      if (val == 1) {
        this.exammarkList.forEach((index) => {
          questionList.push(index.questionNum);
        });
        this.calculateQuestion = questionList;
      }
      if (val == 2) {
        this.exammarkList.forEach((index) => {
          if (this.calculateQuestion.indexOf(index.questionNum) == -1) {
            questionList.push(index.questionNum);
          }
        });
        this.calculateQuestion = questionList;
      }
      if (val == 3) {
        this.calculateQuestion = questionList;
      }
    },
    // 选做题页面
    goChoose() {
      this.$router.push({
        name: "ExamChooseQuestion",
        query: this.examInformationPaper,
      });
    },
    // 绑定批阅
    goBindQuestuion() {
      this.$router.push({
        name: "ExamBindQuestion",
        query: this.examInformationPaper,
      });
    },
    // 分项给分
    setScore(type) {
      if (type != 1) {
        removeStore({
          name: "questionId",
          type: 1,
        });
      }
      removeStore({
        name: "exammarkPointItem",
        type: 1,
      });
      removeStore({
        name: "question",
        type: 1,
      });
      // removeStore({
      //   name: "tId",
      //   type: 1,
      // });
      // console.log(this.examInformationPaper)
      this.$router.push({
        name: "ExamTaskRules",
        query: this.examInformationPaper,
      });
    },
    // 切换考试分工类型
    exammarkMarkType() {
      let data = { examPaperId: this.examInformationPaper.paperId, type: 2 };
      this.markTypeLoading = true;
      // console.log()
      let bindQuesiton = this.exammarkList.filter(
        (item) => item.bindQuestionNum
      );
      let fxQuestion = this.exammarkList.filter((item) => item.markType == 3);
      if (bindQuesiton.length > 0) {
        this.$alert("请删除绑定批阅后再切换为班级批阅", "温馨提示", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        this.markTypeLoading = false;
        return;
      }
      if (fxQuestion.length > 0) {
        this.$alert("请删除分项给分后再切换为班级批阅", "温馨提示", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        this.markTypeLoading = false;
        return;
      }
      exammarkMarkType(data)
        .then(() => {
          this.markTypeLoading = false;
          this.$message({
            type: "success",
            message: "切换成功!",
          });
          let data = Object.assign({}, this.examInformationPaper);

          this.$router.push({
            name: "ExamClassReadCorrectTask",
            query: data,
          });
        })
        .catch(() => {
          this.markTypeLoading = false;
        });
    },
    // 目前只能单选老师类型  后面修改后 此方法删除
    checkTeacherTypeList() {
      // console.log(list);
      // if (list.length > 0) {
      //   this.teacherTypeList = [list[list.length - 1]];
      // }
    },
    // 全选 反选 取消
    checkListChange(val) {
      if ("checkAll" == val) {
        let arr = [];
        this.exammarkList.forEach((item) => {
          if (
            item.sheetDetailId != this.indexQuestion.sheetDetailId &&
            item.teacherList.length == 0
          ) {
            arr.push(item.sheetDetailId);
          }
        });
        this.questionCheckList = arr;
      }

      if ("checkFalse" == val) {
        this.checkCancel = false;
        this.checkAll = false;
        let arr = [];
        this.exammarkList.forEach((item) => {
          if (
            this.questionCheckList.indexOf(item.sheetDetailId) == -1 &&
            item.teacherList.length == 0
          ) {
            arr.push(item.sheetDetailId);
          }
        });
        this.questionCheckList = arr;
      }
      if ("checkCancel" == val) {
        this.checkFalse = false;
        this.checkAll = false;
        this.questionCheckList = [];
      }
      // console.log(this.questionCheckList);
    },
    // 复制老师
    exammarkCopyTeacher() {
      //       copyQuestionNum	被复制的试题题号	query	true
      // string
      // examPaperId	复制到的试卷ID	query	true
      // integer(int32)
      // questionNum	复制到的试题题号（逗号隔开）	query	true
      // string
      // type
      if (this.questionCheckList.length == 0) {
        this.$message({
          message: "请先选择需要复制的试题题号",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.teacherTypeList.length == 0) {
        this.$message({
          message: "请先选择复制老师类型",
          type: "warning",
          showClose: true,
        });
        return;
      }

      let data = {
        copySheetDetailId: this.indexQuestion.sheetDetailId,
        examPaperId: this.examInformationPaper.paperId,
        sheetDetailId: this.questionCheckList.join(","),
        type: this.teacherTypeList.join(","),
      };
      this.btnLoading = true;
      exammarkCopyTeacher(data)
        .then(() => {
          this.btnLoading = false;
          this.copyTeacherdialogVisible = false;
          this.exammarkTeacherList();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    ExamSchoolReadCorrectTask() {
      this.$router.push({
        name: "ExamSchoolReadCorrectTask",
        query: this.examInformationPaper,
      });
    },
    setScoreRules(type) {
      this.examInformationPaper.type = type;
      this.examInformationPaper.fromUrl = this.$route.name;
      this.$router.push({
        name: "ExamSetTaskRules",
        query: this.examInformationPaper,
      });
    },
    // 导出阅卷分工
    exammarkExportOutline() {
      this.exportLoding = true;
      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      exammarkExportOutline(data)
        .then((res) => {
          // console.log(res);
          fileDownloadByUrl(res.data.data.url, res.data.data.examPaperName);
          this.exportLoding = false;
        })
        .catch(() => {
          this.exportLoding = false;
        });
    },
    //导出阅卷分工提纲
    exammarkExportOutMark() {
      this.exportLoding = true;
      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      exammarkExportOutMark(data)
        .then((res) => {
          // console.log(res);
          fileDownloadByUrl(res.data.data.url, res.data.data.name);
          this.exportLoding = false;
        })
        .catch(() => {
          this.exportLoding = false;
        });
    },
    // 清除阅卷分工老师
    clearTeacherList() {
      // console.log(this.exammarkList);
      let hasMarkArr = [];
      this.exammarkList.forEach((item) => {
        if (item.hasMark) {
          hasMarkArr.push(item.questionNum);
        }
      });
      if (hasMarkArr.length > 0) {
        this.$message({
          message: `第${hasMarkArr.join(",")}已有老师阅卷，禁止批量清空！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$confirm("此操作将永久清除分工老师, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        showClose: true,
      })
        .then(() => {
          let data = {
            examPaperId: this.examInformationPaper.paperId,
          };
          exammarkClearTeacher(data).then(() => {
            this.exammarkTeacherList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {});
    },
    exammarkSaveTeacher(data) {
      this.btnLoading = true;
      exammarkSaveTeacher(data)
        .then(() => {
          this.exammarkTeacherList();
          this.teacherDialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 保存老师任务
    submitTeacherList() {
      // console.log(this.markingTeacher);
      // if(averageTask)
      // 加个判断  如果是已经阅卷
      let arr = [];
      // 存在出错的数据 已阅量低于任务量
      let teacherErr = [];
      // let teacherError=[]
      // 是否已有老师阅卷  如果有  后边默认分配都给予老师最大值
      this.isHasMark = false;
      this.markingTeacher.map((index) => {
        if (index.workload == "") {
          arr.push(index.name);
        }
        if (index.teacherHasMark > index.workload && index.type != 4) {
          teacherErr.push(index.name);
        }
        // if (index.teacherHasMark >0 && index.type == 4) {
        //   teacherError.push(index.name);
        // }
        if (index.teacherHasMark) {
          this.isHasMark = true;
        }
      });
      if (this.markingTeacher.length == 0) {
        this.$message({
          message: "至少设置一位老师！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (teacherErr.length > 0) {
        this.$message({
          message: `${teacherErr.join(",")}老师已批阅量大于任务量，设置失败`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      // if (teacherErr.length > 0) {
      //   this.$message({
      //     message: `${teacherErr.join(",")}老师已批阅量大于任务量，设置失败`,
      //     type: "warning",
      //     showClose: true,
      //   });
      //   return;
      // }
      // 关闭的时候
      this.downType = false;
      // 判断下 如果每个老师都没设置任务量  就平均分配
      if (
        arr.length == this.markingTeacher.length &&
        this.indexTeacherType != 4
      ) {
        this.downType = true;
        this.averageTask();
      }
      // 如果部分老师设置了任务量  就将没有设置任务量的教师任务量设为最大值
      if (
        arr.length != this.markingTeacher.length &&
        this.indexTeacherType != 4
      ) {
        this.setTeacherWork();
      }
      let data = {
        markId: this.indexQuestion.id || "",
        examPaperId: this.examInformationPaper.paperId,
        sheetDetailId: this.indexQuestion.sheetDetailId,
        questionCount: this.indexQuestion.questionCount,
      };
      // console.log(this.indexQuestion);
      let oldMarkingTeacher = this.indexQuestion.teacherList.filter(
        (item) => item.type != this.indexTeacherType
      );
      // console.log(oldMarkingTeacher);
      // if (data) {
      //   return;
      // }
      // console.log(arr)
      data.markingTeacher = oldMarkingTeacher.concat(this.markingTeacher);
      this.exammarkSaveTeacher(data);
    },
    // 清空
    clearAll() {
      let strArr = [];
      this.markingTeacher.forEach((item) => {
        if (item.teacherHasMark) {
          strArr.push(item.name);
        }
      });

      if (strArr.length > 0) {
        this.$message({
          message: `${strArr.join(",")}老师已阅卷，禁止取消全选！`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.markingTeacher = [];
      this.checkTeacherList = [];
    },
    setVal() {
      // console.log(this.averageVal);
      if (!this.averageVal) {
        this.$message({
          message: "请输入任务量",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let str = /^[0-9]*$/;
      if (!str.test(this.averageVal)) {
        this.$message({
          message: "只能输入数字",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.isHasMark = false;
      this.averageDialogVisible = false;
      this.averageTask(parseInt(this.averageVal));
    },
    setTeacherWork() {
      let questionCount = this.sheetCount.examStudentCount;

      let questionCount1 = "";
      // 如果是双评  任务量就应该*2
      if (this.indexQuestion.ruleScore != 1 && this.indexTeacherType == 1) {
        questionCount1 = questionCount * 2;
      } else {
        questionCount1 = questionCount;
      }

      this.markingTeacher = this.markingTeacher.map((item) => {
        if (!item.workload || item.workload == 0) {
          item.workload = questionCount1 || 1;
        }
        return item;
      });
    },
    // 平均分配
    averageTask(val) {
      if (this.markingTeacher.length == 0) {
        this.$message({
          message: "请先选择老师",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let questionCount = val || this.sheetCount.examStudentCount;

      let questionCount1 = "";
      // 如果是双评  任务量就应该*2
      if (this.indexQuestion.ruleScore != 1 && this.indexTeacherType == 1) {
        questionCount1 = questionCount * 2;
      } else {
        questionCount1 = questionCount;
      }

      let teacherList = this.markingTeacher.length;
      this.markingTeacher.map((item) => {
        // 如果说本来就有老师情况  按最大值赋值
        item.workload = parseInt(questionCount1 / teacherList);
        let workload = item.workload;
        if (!item.workload) {
          item.workload = 1;
        }
        teacherList--;
        if (item.teacherHasMark > workload) {
          workload = item.teacherHasMark;
        }
        questionCount1 = questionCount1 - workload;
      });
    },
    // 设置教师批阅数量
    setTeacherCnt() {
      if (this.markingTeacher.length == 0) {
        this.$message({
          message: "请先选择老师",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let teacherNumber = 0;
      if (this.teacherNumber) {
        teacherNumber = Number(this.teacherNumber);
      }
      this.markingTeacher.map((index) => {
        index.workload = teacherNumber || 1;
      });
    },
    // 全选  反选
    changeAll(type) {
      if (type == 1) {
        this.teacherList.forEach((item) => {
          if (this.checkTeacherList.indexOf(item.teacherId) == -1) {
            this.checkTeacherList.push(item.teacherId);
          }
        });
      } else {
        // console.log();
        let strArr = [];
        this.markingTeacher.forEach((item) => {
          if (item.teacherHasMark) {
            strArr.push(item.name);
          }
        });

        if (strArr.length > 0) {
          this.$message({
            message: `${strArr.join(",")}老师已阅卷，禁止取消全选！`,
            type: "warning",
            showClose: true,
          });
          return;
        }
        this.teacherList.forEach((item) => {
          this.checkTeacherList = this.checkTeacherList.filter(
            (index) => index != item.teacherId
          );
        });
      }
      // console.log(this.checkTeacherList);
      this.setMarkingList();
    },
    // 删除单个教师
    delTeacher(id) {
      // console.log(this.markingTeacher);
      // console.log();
      let teacherName = "";
      this.markingTeacher.map((item) => {
        if (item.teacherId == id && item.teacherHasMark) {
          teacherName = item.name;
        }
      });
      if (teacherName) {
        this.$message({
          message: `${teacherName}老师已阅卷，不能删除`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.checkTeacherList = this.checkTeacherList.filter((i) => i != id);
      this.setMarkingList();
    },
    // 设置右侧列表数据
    setMarkingList() {
      this.markingTeacher.map((item) => {
        // 首先过滤取消了的试题
        if (this.checkTeacherList.indexOf(item.teacherId) == -1) {
          this.markingTeacher = this.markingTeacher.filter(
            (index) => index.teacherId != item.teacherId
          );
        }
      });
      // 添加列表中不存在的试题
      this.checkTeacherList.map((item) => {
        let type = true;
        this.markingTeacher.map((index) => {
          if (index.teacherId == item) {
            type = false;
          }
        });
        if (type) {
          let name = this.teacherList.filter(
            (index) => item == index.teacherId
          )[0].name;
          let data = Object.assign({}, this.markingNum);
          data.teacherId = item;
          data.name = name;
          data.type = this.indexTeacherType;
          this.markingTeacher.push(data);
        }
      });
    },
    // 学段修改
    levelChange() {
      this.subjectOptions = getSubjectList(this.modelSearch.level);
      let filterList = this.subjectOptions.filter(
        (item) => this.modelSearch.subjectId == item.subjectId
      );
      if (filterList.length == 0) {
        this.modelSearch.subjectId = "";
      }
      this.schoolteacherlistv2();
    },
    gradeChange() {
      this.schoolteacherlistv2();
    },
    // 获取班级列表
    changeClassOptions() {
      if (this.modelSearch.level && this.modelSearch.year) {
        let query = {
          level: this.modelSearch.level,
          year: this.modelSearch.year,
        };
        this.modelSearch.classNum = "";
        // this.modelSearch.classNum = this.classOptions[0].id;
        getClassListByLevelAndYear(query).then((response) => {
          this.classOptions = response.data.data;
          this.modelSearch.classNum = "";
          if (this.classOptions.length > 0) {
            this.schoolteacherlistv2();
          }
        });
      }
    },
    // 获取所有年级列表
    getSchoolYearList() {
      getGradeList().then((response) => {
        let grades = response.data.data;
        if (grades && grades.length > 0) {
          grades.forEach((item) => {
            if (item.level === 2) {
              this.yearMiddleOptions.push(item.year);
            } else {
              this.yearHignOptions.push(item.year);
            }
          });
          this.yearHignOptions = [...new Set(this.yearHignOptions)];
          this.yearMiddleOptions = [...new Set(this.yearMiddleOptions)];
        }
      });
    },
    selectChange() {
      this.schoolteacherlistv2();
    },
    // 根据搜索条件获取老师列表
    schoolteacherlistv2() {
      if (this.examInformationPaper.examType == 1) {
        this.modelSearch.schoolId = this.schoolId;
      }
      schoolteacherlistv2(this.modelSearch).then((res) => {
        // console.log(res);
        this.teacherList = res.data.data;
      });
    },
    //显示教师列表列表
    showTeacherList(type, val) {
      this.markingTeacher = [];
      this.checkTeacherList = [];
      this.indexQuestion = JSON.parse(JSON.stringify(val));
      this.indexQuestion.teacherList.map((index) => {
        if (index.type == type) {
          this.checkTeacherList.push(index.teacherId);
          this.markingTeacher.push(index);
        }
      });
      this.indexTeacherType = type;
      this.teacherNumber = this.sheetCount.examStudentCount;
      this.teacherDialogVisible = true;
    },
    // 步长类型切换
    markStepChange() {
      if (this.indexQuestion.markStep == 1) {
        if (this.oldIndexQuestion.markStep == 2) {
          this.indexQuestion.markScore = 1;
        } else {
          this.indexQuestion.markScore = this.oldIndexQuestion.markScore;
        }
      } else {
        let arr = [];
        let number = "";
        if (this.oldIndexQuestion.markStep == 1) {
          number = parseInt(
            this.indexQuestion.questionScore / this.oldIndexQuestion.markScore
          );
        } else {
          number = Number(this.indexQuestion.questionScore);
        }

        // console.log();
        for (let i = 0; i <= number; i++) {
          if (this.oldIndexQuestion.markStep == 1) {
            arr.push((i * (this.oldIndexQuestion.markScore * 10)) / 10);
          } else {
            arr.push(i);
          }
        }
        // 判断下是否有余值
        if (this.oldIndexQuestion.markStep == 1) {
          if (
            this.indexQuestion.questionScore % this.oldIndexQuestion.markScore >
            0
          ) {
            arr.push(this.indexQuestion.questionScore);
          }
        }

        this.indexQuestion.markScore = arr.join(",");
      }
      // console.log(this.indexQuestion);
    },
    // 设置给分方式
    submitScoreSet() {
      let type = false;
      let str = "";
      if (this.indexQuestion.markStep == 1) {
        if (this.indexQuestion.markScore > this.indexQuestion.questionScore) {
          type = true;
        }
        if (
          this.indexQuestion.markScore === 0 ||
          this.indexQuestion.markScore === "0"
        ) {
          type = true;
          str = "步长不能设置为0分！";
        }
      } else {
        this.indexQuestion.markScore = this.indexQuestion.markScore.replaceAll(
          "，",
          ","
        );
        let re = /^\d+(?=\.{0,1}\d+$|$)/;
        let markScoreList = this.indexQuestion.markScore.split(",");
        markScoreList.forEach((item) => {
          // console.log(re.test(item));
          if (!re.test(item)) {
            type = true;
          }
          if (item > this.indexQuestion.questionScore) {
            type = true;
          }
          if (item.split(".")[1] && item.split(".")[1].length > 2) {
            type = true;
          }
        });
      }
      if (type) {
        this.$message({
          message: str ? str : "步长无效",
          type: "warning",
          showClose: true,
        });
        return;
      }
      // if (this.indexQuestion) {
      //   return;
      // }
      this.exammarkRuleScore(2);
    },
    // 设置评分规则-设置给分方式
    exammarkRuleScore(type) {
      // type 1 评分规则 2 给分方式
      let data = {
        examPaperId: this.examInformationPaper.paperId,
        type: type,
        ruleScore: [this.indexQuestion],
      };
      if (this.indexQuestion.bindQuestionNum) {
        let exammarkList = JSON.parse(JSON.stringify(this.exammarkList));
        let arr = [];
        arr.push(this.indexQuestion);
        exammarkList.map((item) => {
          if (
            this.indexQuestion.bindQuestionNum.indexOf(item.questionNum) != -1
          ) {
            if (this.indexQuestion.sheetDetailId != item.sheetDetailId) {
              if (
                item.ruleScore == this.indexQuestion.ruleScore &&
                item.markStep == this.indexQuestion.markStep
              ) {
                arr.push(item);
              } else {
                item.markStep = this.indexQuestion.markStep;
                item.ruleScore = this.indexQuestion.ruleScore;
                if (type == 2) {
                  if (item.markStep == 1) {
                    item.markScore = this.indexQuestion.markScore;
                  } else {
                    let arr = [];
                    let number = Number(item.questionScore);
                    for (let i = 0; i <= number; i++) {
                      arr.push(i);
                    }
                    // 判断下是否有余值

                    if (item.questionScore % 1 > 0) {
                      arr.push(item.questionScore);
                    }

                    item.markScore = arr.join(",");
                  }
                } else {
                  item.ruleScore = this.indexQuestion.ruleScore;
                  item.scaleType = this.indexQuestion.scaleType;
                  item.carryType = this.indexQuestion.carryType;
                }

                arr.push(item);
              }
            }
          }
        });
        data.ruleScore = arr;
      }
      // if (this.indexQuestion.bindQuestionNum && type == 1) {
      //   let ruleScoreArr = [];
      //   let array = this.indexQuestion.bindQuestionNum.split(",");

      //   array.forEach((item) => {
      //     let newData = Object.assign({}, this.indexQuestion);
      //     newData.questionNum = item;
      //     ruleScoreArr.push(newData);
      //   });
      //   data.ruleScore = ruleScoreArr;
      // }

      this.btnLoading = true;
      exammarkRuleScore(data)
        .then(() => {
          this.$message({
            message: "修改成功！",
            type: "success",
            showClose: true,
          });
          this[this.indexModel] = false;
          this.btnLoading = false;
          // 更新本地数据
          // this.setList()
          this.exammarkTeacherList();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 提交评分规则-设置给分方式
    submitScore() {
      // console.log(this.indexQuestion.ruleScore);
      let errorScore = false;
      let scaleType = false;
      let carryType = false;
      // let errorScoreArr = [];
      if (this.indexQuestion.ruleScore != 1) {
        if (
          !this.indexQuestion.errorScore &&
          this.indexQuestion.errorScore !== 0
        ) {
          errorScore = true;
        }
        if (!this.indexQuestion.scaleType) {
          scaleType = true;
        }
        if (!this.indexQuestion.carryType) {
          carryType = true;
        }
        // errorScoreArr = this.indexQuestion.errorScore.split(".");
      }
      // console.log(this.indexQuestion.errorScore);
      // console.log(errorScore);
      if (errorScore) {
        this.$message({
          message: "请输入误差分！",
          type: "warning",
          showClose: true,
        });
        return;
      }

      if (this.indexQuestion.errorScore > this.indexQuestion.questionScore) {
        this.$message({
          message: "误差分不能大于小题总分！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (scaleType) {
        this.$message({
          message: "请选择小数位数！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (carryType) {
        this.$message({
          message: "请选择进位方式！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.exammarkRuleScore(1);
      // ruleScore errorScore scaleType carryType
    },
    setIndexQuestionRules(question, type) {
      if (type == "scoreSetDialogVisible" && question.markType == 3) {
        // console.log(question);
        if (question.hasMark) {
          this.$message({
            showClose: true,
            message: "已有老师阅卷，不可更改给分方式",
            type: "warning",
          });
          return;
        }

        setStore({
          name: "questionId",
          type: 1,
          content: question.sheetDetailId,
        });
        this.$refs.scoreMethodModel.init(question);
        // this.setScore(1);
        return;
      }
      // if (type == "scoreRolesdialogVisible" && question.markType == 3) {
      //   return;
      // }
      this.indexModel = type;
      // console.log(type);
      this.indexQuestion = Object.assign({}, question);
      this.oldIndexQuestion = Object.assign({}, question);
      if (this.indexQuestion.carryType == 0) {
        this.indexQuestion.carryType = "";
      }
      if (this.indexQuestion.scaleType == 0) {
        this.indexQuestion.scaleType = "";
      }

      //  questionCheckList teacherTypeList
      this.teacherTypeList = [];
      this.questionCheckList = [];
      this[type] = true;
      // console.log(question);
    },
    // 获取学校列表

    schoolExamwork() {
      let data = {
        examPaperId: this.examInformationPaper.paperId,
        schoolId: this.schoolId,
      };
      if (this.$route.name == "unionExamCorrectTask") {
        data.schoolId = getStore({ name: "school_id" });
      }
      this.listLoading = true;
      schoolExamwork(data)
        .then((res) => {
          if (res.data.data.length == 0) {
            this.exammarkList = [];
          }
          // 添加两个显示任务量的弹窗
          res.data.data.map((item) => {
            item.visible = false;
            item.visible1 = false;
            item.markTime = item.markTime || 0;
            item.examMarkType = item.examMarkType || "";
          });
          // markTime

          this.exammarkList = res.data.data;
          // console.log(this.exammarkList);
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    exammarkTeacherList() {
      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      if (this.examInformationPaper.examType == 1) {
        data.schoolId = this.schoolId;
        if (this.$route.name != "ExamCorrectTask") {
          data.schoolId = getStore({ name: "school_id" });
        }
      }
      // schoolId
      this.listLoading = true;
      // console.log(data);
      exammarkTeacherList(data)
        .then((res) => {
          // console.log(res);
          if (res.data.data.length > 0) {
            // 添加两个显示任务量的弹窗
            res.data.data.map((item) => {
              item.visible = false;
              item.visible1 = false;
              item.markTime = item.markTime || 0;
              item.useMarkTime = item.useMarkTime || 0;
              item.examMarkType = item.examMarkType || "";
            });
          }

          this.exammarkList = res.data.data;

          // console.log(this.exammarkList);
          this.listLoading = false;
          // console.log(res);
        })
        .catch(() => {
          // console.log(err);
          this.listLoading = false;
        });
    },
    // 获取试题是否已全部设置为分项给分
    getListDis() {
      let type = true;
      this.exammarkList.map((item) => {
        if (item.markType != 3) {
          type = false;
        }
      });
      return type;
    },
    goBack() {
      this.$router.push({
        name: "examlist",
        query: { examId: this.examInformationPaper.examId },
        // :url="'examlist'"
      });
    },
  },
};
</script>
<style lang="scss">
.el-popover {
  padding: 6px;
}
.ExamCorrectTask {
  .set-up-rules {
    .el-table__header {
      th {
        padding: 18px 0;
      }
    }
    .cell {
      padding: 0;
    }
  }
}
.type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px !important;
  .spotType {
    display: flex;
    align-items: center;
    i {
      color: #2474ed;
      margin-right: 4px;
    }
    .el-radio {
      margin-bottom: 0;
      margin-right: 24px;
    }
    .detail {
      text-decoration: underline;
      color: #a1a5ad;
      cursor: pointer;
    }
    .detail_active {
      text-decoration: underline;
      color: #2474ed;
      cursor: pointer;
    }
  }
  .taskType {
    display: flex;
    align-items: center;
    .el-radio {
      margin-bottom: 0;
      margin-right: 24px;
    }
    .detail {
      text-decoration: underline;
      color: #a1a5ad;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.ExamCorrectTask {
  padding: 24px 0 60px;

  .score-set-box {
    // > div {
    //   width: 100%;
    //   display: flex;
    //   align-items: flex-start;
    // }
    .el-radio {
      margin-bottom: 0;
    }
    .score {
      color: #ff0505;
      margin-left: 12px;
    }
    .time-title {
      span {
        margin-right: 4px;
        padding: 0px 6px;
        font-size: 12px;
        border-radius: 50%;
        background-color: #2474ed;
        color: #ffffff;
        letter-spacing: 0;
      }
    }
    .tips {
      padding: 12px;
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;

      span {
        color: #e48469;
        margin-right: 4px;
        &:first-child {
          padding: 0px 6px;
          font-size: 12px;
          border-radius: 50%;
          background-color: #f17e5e;
          color: #ffffff;
          letter-spacing: 0;
        }
      }
    }
    > div {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: $primary-color;
    font-size: 16px;
  }
  .average-dialogVisible {
    .el-input {
      width: 118px;
    }
    .nums {
      color: $primary-color;
    }
  }
  .score-model {
    p {
      margin: 24px 0;
      &:first-child {
        margin-top: 0;
      }
    }
    img {
      width: 100%;
    }
  }
  .upload-demo {
    ::v-deep .el-upload__text {
      line-height: 130px;
    }
    ::v-deep .el-upload {
      display: block;
    }
    ::v-deep .el-upload-dragger {
      width: 100%;
      height: 130px;
    }
  }
  .down-file {
    margin: 20px 0;
  }
  .examination-tips {
    background: #f2f8fe;
    border: 1px dashed #2474ed;
    padding: 13px 12px 0 36px;
    div {
      margin-bottom: 12px;
      &:first-child {
        position: relative;
        &::after {
          content: "!";
          letter-spacing: 0;
          position: absolute;
          left: -18px;
          top: 3px;
          width: 13px;
          height: 13px;
          background: #2474ed;
          border-radius: 50%;
          text-align: center;
          font-size: 12px;
          color: #ffffff;
          line-height: 12px;
        }
      }
    }
  }
  .task-num {
    .task-num-margin {
      margin: 24px 0;
    }
    .check-question-list {
      margin-top: 24px;
      .el-checkbox {
        margin-bottom: 12px;
      }
    }
    .el-radio {
      margin-bottom: 0;
    }
    .el-checkbox {
      margin-bottom: 0;
    }
  }
  .model-teacher-box {
    .model-teacher-box-btn {
      margin-bottom: 12px;
      .el-button {
        margin-right: 12px;
      }
    }
    .check-box {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-checkbox {
      margin-bottom: 24px;
      // min-width: 110px;
    }
    .model-teacher-type {
      display: flex;
      // margin: 12p 0;
    }
    .model-tips {
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;
      padding: 12px 32px;
      position: relative;
      div {
        position: absolute;
        left: 13px;
        top: 16px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        display: inline-block;
        background: #f17e5e;
        letter-spacing: 0;
        font-size: 12px;
        text-align: center;
        line-height: 13px;
        color: #ffffff;
        margin-right: 6px;
      }
      span {
        color: #e48469;
      }
    }
  }

  .set-teacher-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .teacher-list-box {
      width: 712px;
      border: 1px solid #dee1e7;
      height: 544px;
      overflow: hidden;
      .teacher-list-header {
        background: #f5f7fa;
        padding: 9px 24px;
      }
      .teacher-list-number-box {
        padding: 24px 12px 0 24px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        max-height: 424px;

        .teacher-item {
          // min-width: 160px;
          padding: 8px 12px;
          margin-right: 8px;
          border: 1px dashed #dee1e7;
          margin-bottom: 24px;
          letter-spacing: 0;
          i {
            cursor: pointer;
            color: $primary-color;
            margin-left: 8px;
          }
          .el-input {
            width: 62px;
          }
        }
      }
      .teacher-cnt {
        display: flex;
        padding: 24px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dee1e7;

        .teacher-btn-cnt {
          .el-button {
            border-bottom: 1px solid $primary-color;
            padding-bottom: 2px;
          }
        }
        .el-input {
          width: 118px;
          margin-right: 24px;
        }
      }
    }
    .check-teacher-list {
      border: 1px solid #dee1e7;
      margin-top: 24px;
      width: 100%;
      .check-list {
        padding: 24px;
        height: 338px;
        overflow-y: scroll;
        .el-checkbox {
          display: block;
          margin-bottom: 18px;
        }
      }
      .el-checkbox {
        margin-bottom: 0;
      }
      .check-header {
        background: #f5f7fa;
        padding: 9px 24px;
      }
    }
    .teacher-search-box {
      width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .school-list {
        width: 48%;
        margin-bottom: 24px;
      }
      .search-item {
        margin-bottom: 24px;
        .el-select {
          width: 118px;
        }
      }
    }
  }
  .tab-teacher-list-box {
    border-top: 1px solid #ebeef5;
    padding: 6.5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .task-num {
      color: #fe5f59;
    }
    .zc-task {
      font-size: 12px;
      color: #fe5f59;
      margin-left: 12px;
      line-height: 28px;
    }
    .warning-box {
      border: 1px solid rgb(255, 190, 190);
      background-color: #ffffff;
      padding: 6px 10px;
      border-radius: 2px;
      color: #fe5f59 !important;
      line-height: 14px;
    }
    .task-box {
      border: 1px dashed #fe5f59;
      background: #fdf0ee;
      border-radius: 2px;
      color: #fe5f59;
      padding: 1px 6px;
      margin-left: 12px;
      position: relative;
      font-size: 12px;
      &::before {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #fdf0ee;
        content: "";
        transform: rotate(45deg);
        -ms-transform: rotate(45deg); /* IE 9 */
        -moz-transform: rotate(45deg); /* Firefox */
        -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
        -o-transform: rotate(45deg);
        border: 1px dashed #fe5f59;
        left: -5px;
        top: 8px;
      }
      &::after {
        position: absolute;
        width: 7px;
        height: 16px;
        background: #fdf0ee;
        content: "";
        left: 0px;
        top: 5px;
      }
      // border: 1px solid #D8D8D8;;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      width: 95%;
    }
    .teacher-list-name {
      margin-left: 12px;
      line-height: 28px;
    }
    .null-num {
      color: #ff0000;
    }
    .end-num {
      border: 1px solid #dddddd;
      background-color: #ffffff;
      color: #606266 !important;
      span {
        color: #606266 !important;
      }
    }
    .el-button.tab-set-btn {
      border-bottom: 1px solid $primary-color;
      padding: 6px 0 2px;
    }

    .el-button {
      padding: 6px 10px;
    }

    &:first-child {
      border-top: none;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .score-box {
    .score {
      color: #ff0505;
      margin-left: 12px;
    }
    > div {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .score-title {
        width: 112px;
        text-align: right;
      }
    }
    .tips {
      padding: 12px;
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;
      span {
        color: #e48469;
        margin-right: 4px;
        &:first-child {
          padding: 0px 6px;
          font-size: 12px;
          border-radius: 50%;
          background-color: #f17e5e;
          color: #ffffff;
          letter-spacing: 0;
        }
      }
    }
    .el-radio {
      margin-bottom: 0;
    }
  }
  .rules-tips {
    color: #101011;
    line-height: 24px;
    p {
      font-weight: 500;
      color: #101011;
      margin-bottom: 24px;
    }
    span {
      color: $primary-color;
    }
  }
  .set-up-rules {
    background-color: #ffffff;
    padding: 24px;
    margin-top: 24px;
    .set-score {
      // padding: 0 5px;
      color: $primary-color;
      border-radius: 50%;
      font-size: 16px;
      // color: #ffffff;
      margin-left: 4px;
      letter-spacing: 0;
      vertical-align: middle;
      cursor: pointer;
    }
    .set-list-btn {
      // text-decoration: underline;
      border-bottom: 1px solid $primary-color;
      padding-bottom: 3px;
      display: block;
      margin: 0 auto;
      // line-height: 16px;
    }
    .dis-btn {
      color: #dddddd;
      border-bottom: none;
    }
    .set-btn {
      padding: 5px 8px;
      display: block;
      margin: 0 auto;
      background-color: #ffffff;
      border-radius: 4px;
      color: #9f9f9f;
      margin-top: 4px;
    }
    .el-table {
      margin-top: 30px;
    }
    .rule-type {
      margin-bottom: 24px;
      border-bottom: 1px solid #dee1e3;
      padding-bottom: 24px;
    }
    .rules-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rules-right-tips {
        color: $primary-color;
        font-size: 16px;
        cursor: pointer;
        border-bottom: 1px solid $primary-color;
        margin-left: 12px;
        span {
          padding: 0px 6px;
          letter-spacing: 0;
          background-color: #2474ed !important;
          border-radius: 50%;
          color: #ffffff;
          font-size: 12px;
          margin-right: 4px;
        }
      }
      .rules-left-box {
        font-size: 16px;
        em {
          color: $primary-color;
          font-style: normal;
          // margin-left: 25px;
          cursor: pointer;
          img {
            margin-right: 4px;
          }
        }
        .span-err {
          color: #ff0000;
        }
      }
    }
  }
  .header-box {
    background-color: #ffffff;
    padding: 24px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .el-select {
      width: 112px;
      height: 32px;
    }
    .el-input {
      width: 192px;
    }
    .header-search-box {
      font-weight: 400;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;

      .search-item {
        font-size: 14px;
        margin-right: 24px;
        margin-bottom: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .btn-list {
      margin-bottom: 24px;
    }
    .title {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      color: #080a09;
      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
  // .el-dropdown {
  //   margin-right: 10px;
  // }
}
</style>
